import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { DropDownField } from "components/dropdownField/dropdownField";
import EditableGrid from "components/editableGrid";
import { InputField } from "components/inputField/inputField";
import React, { useState } from "react";
export default function StageTable({
  items,
  handleDelete,
  handleAdd,
  handleSave,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stage, setStage] = useState();
  const columns = [
    {
      title: "Name",
      field: "name",
      type: "text",
      placeholder: "Initial chat",
      isRequired: true,
      editable: true,
    },
    {
      title: "Order",
      field: "order",
      type: "number",
      placeholder: "0",
      isRequired: true,
      editable: true,
    },
    {
      title: "Lead Interviewer",
      field: "leadInterviewer",
      type: "email",
      placeholder: "bobby@company.com",
      isRequired: true,
      editable: true,
    },
    {
      title: "Conversion Rate",
      field: "conversionRate",
      type: "number",
      placeholder: "10",
      isRequired: true,
      editable: true,
    },
  ];

  return (
    <div>
      <EditableGrid
        columns={columns}
        items={items}
        defaultItem={{
          id: "",
          name: "",
          order: 0,
          leadInterviewer: "",
          conversionRate: 10,
        }}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        handleView={(stage) => {
          setStage(stage);
          onOpen();
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={() => {
          setStage(undefined);
          onClose();
        }}
        handleView={(stage) => {
          setStage(stage);
          onOpen();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>Edit</DrawerHeader>
          <DrawerBody>
            {stage &&
              columns
                .filter((c) => c.editable === true)
                .map((col, index) => {
                  if (col.type === "dropdown") {
                    return (
                      <div key={index}>
                        <DropDownField
                          value={stage[col.field]}
                          onChange={(e) =>
                            setStage({
                              ...stage,
                              [col.field]: e.target.value,
                            })
                          }
                          options={col.options}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <InputField
                        value={stage[col.field]}
                        type={col.type}
                        placeholder={col.placeholder}
                        label={col.title}
                        onChange={(v) => setStage({ ...stage, [col.field]: v })}
                        required={col.isRequired}
                      />
                    </div>
                  );
                })}
            <Button
              variant='brand'
              onClick={() => {
                handleSave(stage);
                onClose();
              }}
            >
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
