import { collection, getDocs, query, where } from "firebase/firestore";
import Service from "./service";
import { db } from "./firebase";

export default class DeviceService extends Service {
  constructor(currentUser) {
    super("devices", currentUser);
    this.defaultItem = {
      alias: "",
      scans: [],
    };
  }

  //   async getScansByDevice(hostname, apikey) {
  //     const q = query(
  //       collection(db, "scans"),
  //       where("apikey", "==", apikey),
  //       where("hostname", "==", hostname),
  //       orderBy("finish_time")
  //     );
  //     const snapshot = await getDocs(q);
  //     return snapshot.docs.map((i) => {
  //       return { ...i.data(), id: i.id };
  //     });
  //   }

  async getDevicesByApi(apikey) {
    const q = query(
      collection(db, this.collection),
      where("apikey", "==", apikey)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((i) => {
      return { ...i.data(), id: i.id };
    });
  }
}
