import Service from "./service";

export default class RoleService extends Service {
  constructor(currentUser) {
    super("roles", currentUser);
    this.defaultItem = {
      title: "",
      liveDate: new Date(),
      maxSalary: 0,
      hiringManagerEmail: "",
      candidates: [],
      stages: [
        {
          id: crypto.randomUUID(),
          name: "Initial chat",
          order: 0,
          leadInterviewer: this.currentUser.email,
          conversionRate: 5,
        },
        {
          id: crypto.randomUUID(),
          name: "Skills",
          order: 1,
          leadInterviewer: this.currentUser.email,
          conversionRate: 20,
        },
        {
          id: crypto.randomUUID(),
          name: "Final stage",
          order: 2,
          leadInterviewer: this.currentUser.email,
          conversionRate: 50,
        },
      ],
    };
  }

  calculateAverageSalary(role) {
    if (role.candidates.length === 0) return 0;
    return role.candidates
      .map((c) => c.minSalary)
      .reduce(function (avg, value, _, { length }) {
        return avg + value / length;
      }, 0);
  }
  calculatePipelineValue(role) {
    if (role.candidates.length === 0) return 0;
    return role.candidates
      .map((c) => parseInt(c.chance))
      .reduce((a, b) => a + b);
  }

  findStageByID(role, id) {
    const foundStage = role.stages.find((s) => s.id === id);
    return foundStage || role.stages[0];
  }

  async getRoleByID(id) {
    const role = await this.getByID(id);
    const mappedRole = {
      ...role,
      liveDate: new Date(role.liveDate.seconds * 1000),
      stages: role.stages.sort((a, b) => a.order - b.order),
      candidates: role.candidates.map((c) => {
        const stage = this.findStageByID(role, c.stage);
        return { ...c, chance: stage.conversionRate };
      }),
    };
    return {
      ...mappedRole,
      stats: {
        averageSalary: this.calculateAverageSalary(mappedRole),
        pipelineValue: this.calculatePipelineValue(mappedRole),
      },
    };
  }
}
