/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from "@chakra-ui/react";
import React from "react";
import RoleService from "lib/roles";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useEffect } from "react";

import RolesTable from "./components/RoleTable";
import { useHistory } from "react-router-dom";

export default function Roles() {
  const { user } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);

  const roleService = new RoleService(user);
  const history = useHistory();
  const handleAdd = async (newRole) => {
    await roleService.new({
      ...newRole,
      liveDate: new Date(newRole.liveDate),
    });
    await fetchRoles();
  };

  const handleView = async (role) => {
    history.push("/admin/roles/" + role.id);
  };

  const handleDelete = async (role) => {
    await roleService.delete(role.id);
    await fetchRoles();
  };

  const fetchRoles = async () => {
    const data = await roleService.getByCurrentUser();
    if (roles === data) return;
    setRoles(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchRoles(), []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <RolesTable
        items={roles.map((role) => ({
          ...role,
          nav: "/admin/roles/" + role.id,
        }))}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        handleView={handleView}
      />
    </Box>
  );
}
