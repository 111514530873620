/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button, Text } from "@chakra-ui/react";

import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { InputField } from "components/inputField/inputField";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import UserService from "lib/user";

export default function ConfigurationPage() {
  const { user } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState();
  const [dirtyUserDetails, setDirtyUserDetails] = useState();
  const userService = new UserService(user);

  const saveUserCompanyName = async () => {
    await userService.update(user.uid, {
      ...userDetails,
      companyName: dirtyUserDetails.companyName,
    });
    await fetchUser();
  };

  const generateNewKey = async () => {
    const apikey = userService.generateAPIToken();
    await userService.update(user.uid, { ...userDetails, apikey });
    await fetchUser();
  };

  const fetchUser = async () => {
    const u = await userService.getByID(user.uid);
    console.log(u);
    setUserDetails(u);
    setDirtyUserDetails(u);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchUser();
  }, []);
  if (!userDetails || !dirtyUserDetails) return <p>Loading</p>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Link to={"/admin/roles"}>Back to roles</Link>
      <Tabs>
        <TabList>
          <Tab>Company</Tab>
          <Tab>Agent</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <InputField
              onChange={(companyName) =>
                setDirtyUserDetails({
                  ...dirtyUserDetails,
                  companyName,
                })
              }
              value={dirtyUserDetails.companyName}
              label='Company Name'
            />
            <Button
              disabled={
                dirtyUserDetails.companyName === userDetails.companyName
              }
              onClick={saveUserCompanyName}
            >
              Save
            </Button>
          </TabPanel>
          <TabPanel>
            <Text>API KEY: </Text>
            <p>{userDetails.apikey}</p>
            <Button onClick={generateNewKey}>
              Generate new (Will Invalidate current agents configured to use the
              old API key!){" "}
            </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
