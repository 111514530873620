import React from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { InputField } from "./inputField/inputField";
import { DropDownField } from "./dropdownField/dropdownField";
import { BsEnvelope, BsLinkedin } from "react-icons/bs";

export default function EditableGrid({
  handleDelete,
  handleAdd,
  handleView,
  defaultItem,
  items,
  columns,
  key,
}) {
  const firstField = React.useRef();
  const [newItem, setNewItem] = useState(defaultItem);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAddItem = async () => {
    handleAdd(newItem);
    setNewItem(defaultItem);
    onClose();
  };

  const getDropdownValue = (col, item) => {
    const result = col.options.find((o) => o.key === item[col.field]);
    return result ? result.value : col.options[0].value;
  };
  return (
    <Box>
      {items.length > 0 ? (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                {columns.map((col, index) => (
                  <Th key={index}>{col.title}</Th>
                ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item) => (
                <Tr key={item.id || key}>
                  {columns.map((col, index) => {
                    console.log(col);
                    if (col.type == "link")
                      return (
                        <Td key={index}>
                          <a
                            target='_blank'
                            rel='noreferrer  noopener'
                            href={item[col.field]}
                          >
                            <Icon as={BsLinkedin} />
                          </a>
                        </Td>
                      );
                    if (col.type == "email")
                      return (
                        <Td key={index}>
                          <a
                            target='_blank'
                            rel='noreferrer noopener'
                            href={"mailto://" + item[col.field]}
                          >
                            <Icon as={BsEnvelope} />
                          </a>
                        </Td>
                      );
                    if (col.type == "currency")
                      return (
                        <Td key={index}>
                          £{parseInt(item[col.field]).toLocaleString("en-GB")}
                        </Td>
                      );

                    if (col.type == "dropdown") {
                      return <Td key={index}>{getDropdownValue(col, item)}</Td>;
                    }

                    if (col.type == "date")
                      return (
                        <Td key={index}>
                          {new Date(
                            item[col.field].seconds * 1000
                          ).toLocaleDateString()}
                        </Td>
                      );
                    if (col.type == "progress")
                      return (
                        <Td key={index}>
                          <Progress
                            title={item[col.field] + "%"}
                            variant='table'
                            colorScheme='brandScheme'
                            h='8px'
                            w='108px'
                            value={item[col.field]}
                          />
                        </Td>
                      );
                    return <Td key={index}>{item[col.field]}</Td>;
                  })}
                  <Td>
                    <Button
                      onClick={() => {
                        handleView(item);
                      }}
                      variant='brand'
                    >
                      View
                    </Button>
                    <Button onClick={() => handleDelete(item)}>Delete</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text mb='24px'>No items to display</Text>
      )}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        initialFocusRef={firstField}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>Add a new item</DrawerHeader>

          <DrawerBody>
            {columns
              .filter((c) => c.editable === true)
              .map((col, index) => {
                if (col.type === "dropdown") {
                  return (
                    <div key={index}>
                      <DropDownField
                        value={newItem[col.field]}
                        label={col.title}
                        onChange={(e) =>
                          setNewItem({
                            ...newItem,
                            [col.field]: e.target.value,
                          })
                        }
                        options={col.options}
                      />
                    </div>
                  );
                }
                return (
                  <InputField
                    key={index}
                    value={newItem[col.field]}
                    type={col.type}
                    placeholder={col.placeholder}
                    label={col.title}
                    onChange={(v) => setNewItem({ ...newItem, [col.field]: v })}
                    required={col.isRequired}
                  />
                );
              })}

            <Button variant='brand' onClick={handleAddItem}>
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Button variant='brand' onClick={onOpen}>
        Add
      </Button>
    </Box>
  );
}
