import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdSupervisedUserCircle,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import { IoMdBriefcase, IoMdSettings } from "react-icons/io";
import Roles from "views/admin/roles";
import Role from "views/admin/role";
import Candidate from "views/admin/candidate";
import ConfigurationPage from "views/admin/configuration/configuration";
import DevicesPage from "views/admin/devices/devices";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Role",
    layout: "/admin",
    path: "/roles/:id",
    isHidden: true,
    icon: (
      <Icon as={IoMdBriefcase} width='20px' height='20px' color='inherit' />
    ),
    component: Role,
  },
  {
    name: "Roles",
    layout: "/admin",
    path: "/roles",
    icon: (
      <Icon as={IoMdBriefcase} width='20px' height='20px' color='inherit' />
    ),
    component: Roles,
  },

  {
    name: "Config",
    layout: "/admin",
    path: "/config",
    icon: <Icon as={IoMdSettings} width='20px' height='20px' color='inherit' />,
    component: ConfigurationPage,
  },

  {
    name: "Devices",
    layout: "/admin",
    path: "/devices",
    icon: (
      <Icon as={IoMdBriefcase} width='20px' height='20px' color='inherit' />
    ),
    component: DevicesPage,
  },

  {
    name: "Candidate",
    layout: "/admin",
    path: "/candidates/:id",
    isHidden: true,
    icon: (
      <Icon
        as={MdSupervisedUserCircle}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Candidate,
  },

  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    isHidden: true,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    isHidden: true,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
  },
];

export default routes;
