import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { DropDownField } from "components/dropdownField/dropdownField";
import EditableGrid from "components/editableGrid";
import { InputField } from "components/inputField/inputField";
import React, { useState } from "react";
import { BsLinkedin } from "react-icons/bs";

export default function CandidateTable({
  items,
  handleDelete,
  handleAdd,
  handleSave,
  stageOptions,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [candidate, setCandidate] = useState();
  const columns = [
    {
      title: "",
      field: "linkedin",
      type: "link",
      reactIcon: BsLinkedin,
      placeholder: "https://www.linkedin.com/in/bobbytables/",
      isRequired: true,
      editable: true,
    },
    {
      title: "Name",
      field: "name",
      type: "text",
      placeholder: "Bobby Tables",
      isRequired: true,
      editable: true,
    },
    {
      title: "Email",
      field: "email",
      type: "email",
      placeholder: "bob@company.com",
      isRequired: true,
      editable: true,
    },

    {
      title: "Notice (days)",
      field: "noticeDays",
      type: "number",
      placeholder: "30",
      isRequired: true,
      editable: true,
    },
    {
      title: "Min Salary",
      field: "minSalary",
      type: "currency",
      placeholder: "40000",
      isRequired: true,
      editable: true,
    },
    {
      title: "Location",
      field: "location",
      type: "text",
      placeholder: "London",
      isRequired: true,
      editable: true,
    },

    {
      title: "Stage",
      field: "stage",
      type: "dropdown",
      options: stageOptions,
      placeholder: "",
      isRequired: false,
      editable: true,
    },

    {
      title: "Status",
      field: "status",
      type: "dropdown",
      options: [
        { key: "new", value: "New" },
        { key: "live", value: "Live" },
        { key: "rejected", value: "Rejected" },
        { key: "withdrawn", value: "Withdrawn" },
        { key: "hired", value: "Hired" },
      ],
      placeholder: "",
      isRequired: false,
      editable: true,
    },
    {
      title: "Chance",
      field: "chance",
      type: "progress",
      placeholder: "0",
      isRequired: false,
    },
  ];

  return (
    <div>
      <EditableGrid
        columns={columns}
        items={items}
        defaultItem={{
          id: "",
          name: "",
          email: "",
          noticeDays: 30,
          minSalary: 0,
          location: "",
          progress: "",
          stage: "",
          chance: "",
        }}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        handleView={(candidate) => {
          setCandidate(candidate);
          onOpen();
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        size='xl'
        onClose={() => {
          setCandidate(undefined);
          onClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>Edit</DrawerHeader>

          <DrawerBody>
            {candidate &&
              columns
                .filter((c) => c.editable === true)
                .map((col, index) => {
                  if (col.type === "dropdown") {
                    return (
                      <div key={index}>
                        <DropDownField
                          label={col.title}
                          value={candidate[col.field]}
                          onChange={(e) =>
                            setCandidate({
                              ...candidate,
                              [col.field]: e.target.value,
                            })
                          }
                          options={col.options}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <InputField
                        reactIcon={col.reactIcon}
                        value={candidate[col.field]}
                        type={col.type}
                        placeholder={col.placeholder}
                        label={col.title}
                        onChange={(v) =>
                          setCandidate({ ...candidate, [col.field]: v })
                        }
                        required={col.isRequired}
                      />
                    </div>
                  );
                })}
            <Button
              variant='brand'
              onClick={() => {
                handleSave(candidate);
                onClose();
              }}
            >
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
