/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button, Grid, Text } from "@chakra-ui/react";

import React from "react";
import RoleService from "lib/roles";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { InputField } from "components/inputField/inputField";
import CandidateTable from "./components/CandidateTable";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import StageTable from "./components/StageTable";

export default function Role() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [editMode, setEditMode] = useState(false);
  const roleService = new RoleService(user);
  const [role, setRole] = useState();
  const [dirtyRole, setDirtyRole] = useState();

  const handleSave = async () => {
    await roleService.update(id, dirtyRole);
    await fetchRole();
  };
  const handleEdit = async () => {
    setEditMode(!editMode);
  };

  const handleAddCandidate = async (candidate) => {
    const candidates = [
      ...role.candidates,
      { ...candidate, id: crypto.randomUUID() },
    ];
    await roleService.update(id, { ...role, candidates });
    await fetchRole();
  };

  const handleSaveCandidate = async (candidate) => {
    const candidates = [...role.candidates];
    const mappedCandidates = candidates.map((c) =>
      c.id === candidate.id ? candidate : c
    );
    await roleService.update(id, { ...role, candidates: mappedCandidates });
    await fetchRole();
  };

  const handleSaveStage = async (stage) => {
    const stages = [...role.stages];
    const mappedStages = stages.map((c) => (c.id === stage.id ? stage : c));
    await roleService.update(id, { ...role, stages: mappedStages });
    await fetchRole();
  };

  const handleDeleteCandidate = async (candidate) => {
    await roleService.update(id, {
      ...role,
      candidates: deleteItemFromArray(candidate, role.candidates),
    });
    await fetchRole();
  };

  const handleAddStage = async (stage) => {
    const stages = [...role.stages, { ...stage, id: crypto.randomUUID() }];
    await roleService.update(id, { ...role, stages });
    await fetchRole();
  };

  const handleDeleteStage = async (stage) => {
    await roleService.update(id, {
      ...role,
      stages: deleteItemFromArray(stage, role.stages),
    });
    await fetchRole();
  };

  const deleteItemFromArray = (item, items) => {
    const newItems = [...items];
    const filteredItems = newItems.filter(
      (c) => JSON.stringify(c) !== JSON.stringify(item)
    );
    console.log("Filter", filteredItems);
    return filteredItems;
  };

  const fetchRole = async () => {
    const data = await roleService.getRoleByID(id);
    if (role === data) return;
    setRole(data);
    setDirtyRole(data);
    console.log("Fetch role", data);
    return data;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchRole();
  }, []);
  if (!dirtyRole) return <p>Loading</p>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Link to={"/admin/roles"}>Back to roles</Link>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Candidates</Tab>
          <Tab>Stats</Tab>
          <Tab>Stages</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Grid
              templateColumns={{
                base: "1fr",
                lg: "2fr 1fr",
              }}
              templateRows={{
                base: "repeat(4, 1fr)",
                lg: "1fr",
              }}
              gap={{ base: "20px", xl: "20px" }}
            >
              <InputField
                disabled={!editMode}
                value={dirtyRole.title}
                type='text'
                placeholder='Senior Developer'
                label='Title'
                onChange={(v) => setDirtyRole({ ...dirtyRole, title: v })}
                required={true}
              />
              <InputField
                disabled={!editMode}
                value={dirtyRole.maxSalary}
                type='number'
                placeholder=''
                label='Max Salary'
                onChange={(v) => setDirtyRole({ ...dirtyRole, maxSalary: v })}
                required={true}
              />
              <InputField
                disabled={!editMode}
                value={dirtyRole.hiringManagerEmail}
                type='email'
                placeholder='bob@company.com'
                label='Hiring Manager Email'
                onChange={(v) =>
                  setDirtyRole({ ...dirtyRole, hiringManagerEmail: v })
                }
                required={true}
              />

              <InputField
                disabled={!editMode}
                value={dirtyRole.liveDate}
                type='datetime-local'
                placeholder=''
                label='Live Date'
                onChange={(v) => setDirtyRole({ ...dirtyRole, liveDate: v })}
                required={true}
              />
            </Grid>
            <Button disabled={user.uid !== role.userId} onClick={handleEdit}>
              {editMode ? "Cancel" : "Edit"}
            </Button>
            <Button
              variant='brand'
              disabled={JSON.stringify(dirtyRole) == JSON.stringify(role)}
              onClick={handleSave}
              ml='12px'
            >
              Save
            </Button>
          </TabPanel>
          <TabPanel>
            <Text>Candidates</Text>
            {role.candidates && (
              <CandidateTable
                items={role.candidates}
                handleDelete={handleDeleteCandidate}
                handleAdd={handleAddCandidate}
                handleSave={handleSaveCandidate}
                stageOptions={role.stages.map((s) => ({
                  key: s.id,
                  value: s.name,
                }))}
              />
            )}
          </TabPanel>
          <TabPanel>
            {role.candidates && role.candidates.length > 0 && (
              <div>
                <p>Number of candidates: {role.candidates.length}</p>
                <p>Average salary: £{role.stats.averageSalary}</p>
                <p>Pipeline value: {role.stats.pipelineValue}%</p>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <StageTable
              items={role.stages}
              handleDelete={handleDeleteStage}
              handleAdd={handleAddStage}
              handleSave={handleSaveStage}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
