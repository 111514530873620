/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from "@chakra-ui/react";

import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useEffect } from "react";
import DeviceService from "lib/devices";

export default function DevicesPage() {
  const { user } = useContext(AuthContext);
  const [devices, setDevices] = useState();
  const deviceService = new DeviceService(user);

  const fetchDevice = async () => {
    const d = await deviceService.getDevicesByApi(user.apikey);
    console.log(d);
    setDevices(d);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchDevice();
  }, []);
  if (!devices) return <p>Loading</p>;
  console.log(devices.scans && devices.scans[0].finish_time.toDate());
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ul>
        {devices.map((d) => (
          <li key={d.id}>
            {d.hostname}
            <ul>
              {d.scans.map((s) => (
                <li key={s.id}>{s.finish_time.toDate().toLocaleString()}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Box>
  );
}
