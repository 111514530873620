import EditableGrid from "components/editableGrid";
import React from "react";
export default function RolesTable({
  items,
  handleDelete,
  handleAdd,
  handleView,
}) {
  const columns = [
    {
      title: "Title",
      field: "title",
      type: "text",
      placeholder: "Senior Developer",
      isRequired: true,
      editable: true,
    },
    {
      title: "Hiring Manager Email",
      field: "hiringManagerEmail",
      type: "email",
      placeholder: "bob@company.com",
      isRequired: true,
      editable: true,
    },
    {
      title: "Live Date",
      field: "liveDate",
      type: "date",
      placeholder: "",
      isRequired: true,
      editable: true,
    },
    {
      title: "Max Salary",
      field: "maxSalary",
      type: "currency",
      placeholder: "40000",
      isRequired: true,
      editable: true,
    },
  ];

  return (
    <EditableGrid
      columns={columns}
      items={items}
      defaultItem={{
        id: "",
        title: "",
        hiringManagerEmail: "",
        liveDate: { seconds: 0 },
        maxSalary: 0,
      }}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      handleView={handleView}
    />
  );
}
