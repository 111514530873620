// chakra imports
import {
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React from "react";

export function DropDownField(props) {
  const { label, value, options, onChange, disabled, required } = props;

  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <div>
      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'
        >
          {label}
          {required && <Text color={brandStars}>*</Text>}
        </FormLabel>
        <Select mb='24px' value={value} onChange={onChange} disabled={disabled}>
          {options.map((o) => (
            <option key={o.key} value={o.key}>
              {o.value}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
