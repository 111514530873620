import { db } from "./firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

export default class Service {
  unsub = [];
  defaultItem = {};
  constructor(collection, currentUser) {
    this.collection = collection;
    this.currentUser = currentUser;
  }
  async getByID(id) {
    const docRef = await this.getRef(id);
    const d = await getDoc(docRef);
    if (!d.exists()) return undefined;
    return { ...d.data(), id };
  }

  async new(data) {
    return await addDoc(collection(db, this.collection), {
      ...this.defaultItem,
      ...data,
      userId: this.currentUser.uid,
      createdOn: new Date(),
      updatedOn: new Date(),
      isDeleted: false,
    });
  }

  async getByUser(userID) {
    const q = query(
      collection(db, this.collection),
      where("userId", "==", userID),
      where("isDeleted", "!=", true)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((i) => {
      return { ...i.data(), id: i.id };
    });
  }

  // async syncByUser(userID, callback) {
  //   const q = query(
  //     collection(db, this.collection),
  //     where("userId", "==", userID),
  //     where("isDeleted", "!=", true)
  //   );
  //   this.unsub.push(onQuerySnapshot(q, callback));
  // }

  async unsubscribeAll() {
    this.unsub.forEach((sub) => sub());
  }
  async getByCurrentUser() {
    if (!this.currentUser) return [];
    return await this.getByUser(this.currentUser.uid);
  }

  async getRef(id) {
    if (!id) return undefined;
    return doc(db, `${this.collection}/${id}`);
  }

  async exists(id) {
    if (!id) return undefined;
    const ref = this.getRef(id);
    const d = await getDoc(ref);
    return d.exists();
  }

  async delete(id) {
    await this.update(id, { isDeleted: true });
  }

  async update(id, data) {
    if (!id) return undefined;
    const ref = await this.getRef(id);
    const d = await getDoc(ref);
    if (d.exists()) {
      await updateDoc(ref, { ...data, updatedOn: new Date() });
      return await getDoc(ref);
    } else {
      return undefined;
    }
  }

  async map(data) {
    return { ...data };
  }
}
