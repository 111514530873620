// chakra imports
import {
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { BsCurrencyPound } from "react-icons/bs";

export function InputField(props) {
  const {
    label,
    value,
    type,
    placeholder,
    onChange,
    disabled,
    required,
    reactIcon,
    ref,
    ...rest
  } = props;
  const formatDate = (date) =>
    date.getFullYear().toString().padStart(4, "0") +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    "T" +
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <div>
      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'
        >
          {label}
          {required && <Text color={brandStars}>*</Text>}
        </FormLabel>
        <InputGroup>
          {reactIcon && (
            <InputLeftElement
              pointerEvents='none'
              mt='4px'
              children={<Icon as={reactIcon} />}
            />
          )}
          {type == "currency" && (
            <InputLeftElement
              pointerEvents='none'
              mt='4px'
              children={<Icon as={BsCurrencyPound} />}
            />
          )}
          <Input
            {...rest}
            ref={ref || undefined}
            isRequired={required}
            variant='outline'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type={type || "text"}
            placeholder={placeholder}
            mb='24px'
            fontWeight='500'
            size='lg'
            disabled={disabled}
            value={type === "datetime-local" ? formatDate(value) : value}
            onChange={(e) =>
              type === "datetime-local"
                ? onChange(new Date(e.target.value))
                : onChange(e.target.value)
            }
          />
        </InputGroup>
      </FormControl>
    </div>
  );
}
