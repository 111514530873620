import React from "react";
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";

import { doc, setDoc, getDoc } from "firebase/firestore";

// Firebase auth instance
import { auth, db } from "lib/firebase";
// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const setExpandeduser = async (user) => {
    const docRef = doc(db, "users", user.uid);

    const d = await getDoc(docRef);

    const { avatar, phone, companyName, apikey, isDisabled } = d.data();
    const expandedUser = {
      ...user,
      phone,
      avatar,
      apikey,
      companyName,
      isDisabled,
    };
    console.log("Loaded user", expandedUser);
    return expandedUser;
  };

  React.useEffect(() => {
    // Listener updates auth status when detects change
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsSignedIn(true);
        const expandedUser = await setExpandeduser(user);
        setUser(expandedUser);
      } else {
        setIsSignedIn(false);
        setUser(null);
      }
    });
  }, []);
  // Functions handling auth
  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signUp = async (
    details = {
      name: "",
      email: "",
      phone: "",
      password: "",
    }
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        details.email,
        details.password
      );
      await updateProfile(userCredential.user, {
        displayName: details.name,
      });

      try {
        const docRef = doc(db, "users", userCredential.user.uid);

        await setDoc(docRef, {
          uid: userCredential.user.uid,
          email: details.email,
          phone: details.phone,
          apikey: "",
          companyName: details.name,
          isDisabled: false,
        });
        console.log("New user created with ID: ", docRef.id);
        setIsSignedIn(true);
        const expandedUser = await setExpandeduser(userCredential.user);
        setUser(expandedUser);
      } catch (e) {
        console.error("Error adding document: ", e);
      }

      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };
  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
    } catch (err) {
      console.log(err.message);
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };
  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        signIn,
        signUp,
        signOut,
        googleSignIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
